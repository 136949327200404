import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { PopUpYesNo } from "../../../_components/Popup";
import moment from "moment";
import {
  teacherConstants,
  userConstants,
  configConstants,
} from "../../../_constants";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { teacherService } from "../../../_services";
import $ from "jquery";

function ViewClass() {
  const dispatch = useDispatch();
  const { id } = useParams();
  let { type } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const authentication = useSelector((state) => state.authentication);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const dataDetailClass = useSelector((state) => state.classes.detail || {});
  const persistClassTeacher = useSelector((state) => state.persistClassTeacher.dataClassPersist);
  useEffect(() => {
    if (isLoading) {
      $(".loading").removeClass("hide");
    } else {
      $(".loading").addClass("hide");
    }
  }, [isLoading]);
  
  useEffect(() => {
    if (!!persistClassTeacher?.[id]?.data) {
      dispatch({
        type: teacherConstants.GET_DETAIL_CLASS,
        class: persistClassTeacher?.[id]?.data,
      });
      if (persistClassTeacher?.[id]?.data?.type === 'online') {
        dispatch({
          type: teacherConstants.RESET_DATA_EXERCISE,
        });
      }
      return;
    }
    getData();
  }, []);

  const getData = () => {
    if (type === "offline") {
      dispatch(teacherActions.getDetailClassOffline(id));
    } else {
      setLoading(true);
      teacherService.getDetailClass(id).then((res) => {
        setLoading(false);
        if (res?.data) {
          dispatch({
            type: teacherConstants.GET_DETAIL_CLASS,
            class: res.data,
          });
          dispatch({
            type: teacherConstants.SAVE_DATA_CLASS,
            payload: {
              id,
              data: res.data
            }
          })
        } else {
          type = "offline";
          dispatch(teacherActions.getDetailClassOffline(id));
        }
      });
      dispatch({
        type: teacherConstants.RESET_DATA_EXERCISE,
      });
    }
  }

  function addComponentSignInClass() {
    return (
      <>
        <div className="refresh-curriculum-btn-teacher-header" style={{marginRight: 12}} onClick={getData}>
          <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
        </div>
        {authentication.role === userConstants.ROLE_STUDENT ? (
          <Link to={"/" + authentication.role + "/more/class/apply"}>
            <div className="form-sunE-button mr-10">
              <button className="btn-line-blue">Xin vào lớp mới</button>
            </div>
          </Link>
        ) : (
          <Link
            to={
              "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/edit/" +
              (type || "online")
            }
          >
            <div className="form-sunE-button mr-10">
              <button className="btn-line-blue btn-edit-class">
                <img
                  src="/assets/images/icon/ico_edit_class.png"
                  alt="ico_school"
                />
                Chỉnh sửa
              </button>
            </div>
          </Link>
        )}
      </>)
    ;
  }

  const navigateLink = (url) => {
    if (dataDetailClass.count_student && dataDetailClass.count_student > 0) {
      history.push(url);
    } else {
      setShowPopup(true);
    }
  };

  const updateClassInfo = () => {
    history.push(
      "/" + authentication.role + "/class/view/" + id + "/student/add"
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="sunE-right-container rel">
      {!isEmpty(dataDetailClass) && (
        <Header
          title={dataDetailClass.class_name}
          isBack
          clickBack={() =>
            authentication.role === userConstants.ROLE_STUDENT ? 
            history.go(-1) :
            authentication.role === userConstants.ROLE_TEACHER
              ? history.push("/" + authentication.role + "/class")
              : history.push("/" + authentication.role + "/more/class")
          }
          component={addComponentSignInClass()}
        />
      )}
      {showPopup && (
        <PopUpYesNo
          onClickYes={updateClassInfo}
          onClickNo={() => setShowPopup(false)}
          message={
            "Bạn chưa có học sinh nào. Hãy thêm học sinh để thực hiện chức năng này."
          }
          labelNo={"Hủy"}
          labelYes={"Thêm"}
          width={"280px"}
        />
      )}
      <div className="sunE-content sunE-class-content">
        <div className="sunE-class-index">
          <div className="class-index-box box-shadow">
            <div className="flex-m">
              <div className="class-index-box-img">
                <img
                  className="img-cover"
                  src={configConstants.BASE_URL + dataDetailClass.class_avatar}
                  alt="detail2"
                />
              </div>
              <div className="flex-1 class-box-desc">
                <span className={"class-" + dataDetailClass.type}>
                  {dataDetailClass.type}
                </span>
                <div className="flex-m info-gr">
                  <div className="info-img">
                    <img
                      src="/assets/images/teacher/ico_school.png"
                      alt="ico_school"
                    />
                  </div>
                  <div className="flex-1 info-con">
                    <p>{dataDetailClass.organization_name}</p>
                  </div>
                </div>
                {/* <div className="flex-m info-gr">
                  <div className="info-img">
                    <img
                      src="/assets/images/teacher/ico_clock.png"
                      alt="ico_clock"
                    />
                  </div>
                  <div className="flex-1 info-con">
                    <p>
                      {moment(dataDetailClass.start_time).format("DD/MM/YYYY")}{" "}
                      - {moment(dataDetailClass.end_time).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div> */}
                <div className="flex-m info-gr">
                  <div className="info-img">
                    <img
                      src="/assets/images/teacher/ico_code.png"
                      alt="ico_code"
                    />
                  </div>
                  <div className="flex-1 info-con">
                    <p>{dataDetailClass.class_code}</p>
                  </div>
                </div>
                {dataDetailClass.type === "online" && (
                  <div className="flex-m info-gr">
                    <div className="info-img">
                      <img
                        src="/assets/images/teacher/ico_book.png"
                        alt="ico_book"
                      />
                    </div>
                    <div className="flex-1 info-con">
                      <p>{dataDetailClass.curriculum_name}</p>
                    </div>
                    {authentication.role === userConstants.ROLE_TEACHER && (
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/curriculum"
                        }
                      >
                        <img
                          src="/assets/images/icon/ico_right.png"
                          alt="ico_right"
                        />
                      </Link>
                    )}
                  </div>
                )}
                {authentication.role === userConstants.ROLE_TEACHER && (
                  <div className="flex-m info-gr">
                    <div className="info-img">
                      <img
                        src="/assets/images/teacher/ico_users.png"
                        alt="ico_users"
                      />
                    </div>
                    <div className="flex-1 info-con">
                      <p>
                        Học sinh:{" "}
                        <span className="bold">
                          {dataDetailClass.count_student}
                        </span>
                      </p>
                    </div>
                    <Link
                      to={
                        "/" +
                        authentication.role +
                        "/class/view/" +
                        id +
                        "/student"
                      }
                    >
                      <img
                        src="/assets/images/icon/ico_right.png"
                        alt="ico_right"
                      />
                    </Link>
                  </div>
                )}
                {authentication.role !== userConstants.ROLE_TEACHER && (
                  <div className="flex-m info-gr">
                    <div className="info-img">
                      <img
                        src="/assets/images/teacher/ico_users.png"
                        alt="ico_users"
                      />
                    </div>
                    <div className="flex-1 info-con">
                      <p>
                        Giáo viên: <span>{dataDetailClass.teacher_name}</span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {authentication.role === userConstants.ROLE_TEACHER ? (
            <div className="class-select-content">
              {dataDetailClass.type === "online" ? (
                <div className="class-select-list horizontal-item-between">
                  <div
                    onClick={() =>
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/homework"
                      )
                    }
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/chambai.png"
                          alt="chambai"
                        />
                      </div>
                      <p className="text-light">Chấm bài</p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      dispatch({
                        type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
                        sourcePageAddExcercise: "PAGE_CLASS",
                      });
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/exercise"
                      );
                    }}
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/giaobai.png"
                          alt="giaobai"
                        />
                      </div>
                      <p className="text-light">Giao bài</p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/report"
                      )
                    }
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/baocao.png"
                          alt="baocao"
                        />
                      </div>
                      <p className="text-light one-line">Báo cáo học tập</p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/student/ranking"
                      )
                    }
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/thanhtich.png"
                          alt="thanhtich"
                        />
                      </div>
                      <p className="text-light">Thành tích lớp</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="class-select-list horizontal-item-between">
                  <div
                    onClick={() =>
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/roll_up"
                      )
                    }
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/diemdanh.png"
                          alt="diemdanh"
                        />
                      </div>
                      <p className="text-light">Điểm danh</p>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigateLink(
                        "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/exam_card"
                      )
                    }
                  >
                    <div className="class-select-box box-shadow text-center">
                      <div className="select-img">
                        <img
                          src="/assets/images/teacher/phieudiem.png"
                          alt="phieudiem"
                        />
                      </div>
                      <p className="text-light">Phiếu điểm</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="class-select-content">
              <div className="class-select-list">
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/more/class/view/" +
                    id +
                    "/student"
                  }
                >
                  <div className="class-select-box box-shadow-2 text-center">
                    <div className="select-img">
                      <img
                        src="/assets/images/student/ico_thanhvien.png"
                        alt="ico_thanhvien"
                      />
                    </div>
                    <p className="text-light">Thành viên</p>
                  </div>
                </Link>
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/more/class/view/" +
                    id +
                    "/exersice/" +
                    dataDetailClass.teacher_id +
                    "?page=morepage"
                  }
                >
                  <div className="class-select-box box-shadow-2 text-center">
                    <div className="select-img">
                      <img
                        src="/assets/images/student/ico_baitap.png"
                        alt="ico_baitap"
                      />
                    </div>
                    <p className="text-light">Bài tập</p>
                  </div>
                </Link>
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/more/class/view/" +
                    id +
                    "/student/ranking"
                  }
                >
                  <div className="class-select-box box-shadow-2 text-center">
                    <div className="select-img">
                      <img
                        src="/assets/images/teacher/thanhtich.png"
                        alt="thanhtich"
                      />
                    </div>
                    <p className="text-light">Bảng vàng</p>
                  </div>
                </Link>
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/more/class/view/" +
                    id +
                    "/ranking"
                  }
                >
                  <div className="class-select-box box-shadow-2 text-center">
                    <div className="select-img">
                      <img
                        src="/assets/images/student/ico_vinhdanh.png"
                        alt="ico_vinhdanh"
                      />
                    </div>
                    <p className="text-light">Vinh danh</p>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { ViewClass };
