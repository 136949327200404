import { useDispatch, useSelector } from "react-redux"
import { apiCaller, history } from "../../../../_helpers"
import "./EntranceScoreStudent.style.scss"
import { studentConstants, userConstants } from "../../../../_constants"
import { useEffect, useState } from "react"
import moment from 'moment'
import DoTestExam from "../../../StudentPage/exam-test/DoTestExam"

function secondsToTime(e){
  const m = Math.floor(e % 3600 / 60).toString().padStart(2,'0')
  const s = Math.floor(e % 60).toString().padStart(2,'0');
  return m + ':' + s;
}

export default function EntranceScoreStudent() {
  // const [dataEntranceScore, setDataEntranceScore] = useState()
  const [isTest, setIsTest] = useState(false)
  const authentication = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const dataEntranceScore = useSelector((state) => state.profile.entranceScoreStudent);
  

  const getData = () => {
    const url = '/api_user/entrance_test?' + 'user_id=' + authentication?.id
    apiCaller(url, 'get', {}, null, true).then((res) => {
      dispatch({
        type: studentConstants.GET_ENTRANCE_SCORE,
        payload: res.data
      })
    }).catch(err => {
      alert(err)
    })
  }

  const onBack = () => {
    dispatch({ type: userConstants.HIDE_SIDEBAR_ROOTLESSNESS });
    history.goBack()
  }

  const renderHeader = () => {
    return (
      <div className="bg-header-entrance-score entrance-score-header sunE-right-container">
        <div className="header-part header-top">
          <div className="flex-m">
            <span/>
            <button className="btn-line-blue btn-back" onClick={onBack}>
              Quay lại
            </button>
          </div>
          <p className="entrance-score-label">Điểm kiểm tra đầu vào:</p>
        </div>
        <div className="entrance-score-header-divider" />
        {dataEntranceScore && <div className="header-part header-bottom">
          <span className="entrance-score-value">{dataEntranceScore?.score}</span>
          <div className="origin-vertical justify-content-center" style={{gap: '6px'}}>
            <div className="d-flex align-item-center">
              <span className="size-text-info">{`Thời gian làm bài: `}
              <b className="size-text-info">{secondsToTime(dataEntranceScore?.duration)}</b>
              </span>
            </div>
            <div className="flex-m align-item-center">
              <div className="d-flex align-item-center">
                <span className="size-text-info">{`Số câu đúng/Tổng số câu: `}
                <b className="size-text-info">{`${dataEntranceScore?.number_true || 0}/${dataEntranceScore?.total_question}`}</b>
                </span>
              </div>
            </div>
            <div className="flex-m align-item-center">
              <div className="d-flex align-item-center">
                <span className="size-text-info">{`Thời gian nộp bài: `}
                  <b className="size-text-info">{dataEntranceScore?.time_send}</b>
                </span>
              </div>
            </div>
            {/* <div className="d-flex align-item-center">
              <img src="/assets/images/icon/ico_clock_entrance_white.png" className="entrance-score-icon" alt=""/>
              <span className="entrance-score-time">{dataEntranceScore?.time_send && moment(dataEntranceScore.time_send).format('DD/MM/YYYY HH:mm:ss')}</span>
            </div> */}
          </div>
        </div>}
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className="bg-body-entrance-score entrance-score-body sunE-right-container">
        <div className="origin-vertical align-item-center" style={{padding: '12% 0 24px', height: '100%',justifyContent: 'space-between'}}>
          <div>
            <p className="text-center" style={{fontSize: '24px'}}>Dựa vào kết quả bài kiểm tra, chúng tôi đề xuất bạn học giáo trình</p>
            <p className="text-center" style={{fontSize: '40px', fontWeight: 700}}>{dataEntranceScore?.lost_root ? '"Sunday Essential"' : '"Sunday Success"'}</p>
          </div>
          <button className="btn-line-blue" onClick={() => setIsTest(true)}>
              Làm bài kiểm tra đầu vào
            </button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!dataEntranceScore) {
      getData()
    }
  }, [])

  return (
    <div className="entrance-score-container">
      {renderHeader()}
      {dataEntranceScore && renderBody()}
      {isTest && <DoTestExam isShow={true} rootlessness={true} onClose={() => setIsTest(false)}/>}
    </div>
  )
}